'use strict'

exports.onRouteUpdate = function (_ref, pluginOptions) {
    var location = _ref.location

    if (pluginOptions === void 0) {
        pluginOptions = {}
    }

    if (process.env.NODE_ENV !== 'production' || typeof gtag !== 'function') {
        return null
    }

    var pluginConfig = pluginOptions.pluginConfig || {}
    var pathIsExcluded =
        location &&
        typeof window.excludeGtagPaths !== 'undefined' &&
        window.excludeGtagPaths.some(function (rx) {
            return rx.test(location.pathname)
        })
    if (pathIsExcluded) return null // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)

    var sendPageView = function sendPageView() {
        var pagePath = location ? location.pathname + location.search + location.hash : undefined
        window.gtag('event', 'page_view', {
            page_path: pagePath,
        })
    }

    var _pluginConfig$delayOn = pluginConfig.delayOnRouteUpdate,
        delayOnRouteUpdate = _pluginConfig$delayOn === void 0 ? 0 : _pluginConfig$delayOn

    if ('requestAnimationFrame' in window) {
        requestAnimationFrame(function () {
            requestAnimationFrame(function () {
                return setTimeout(sendPageView, delayOnRouteUpdate)
            })
        })
    } else {
        // Delay by 32ms to simulate 2 requestOnAnimationFrame calls
        setTimeout(sendPageView, 32 + delayOnRouteUpdate)
    }

    return null
}
