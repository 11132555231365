import React from 'react'
import { initKea, wrapElement } from './kea'
import './src/styles/global.css'
import HowWeWorkLayout from './src/templates/HowWeWork'
import Product from './src/templates/Product'
import Market from './src/templates/Market'
import Job from './src/templates/Job'
import Plain from './src/templates/Plain'
// import Event from './src/templates/events/Event'
import GoogleDocsJob from './src/templates/GoogleDocsJob'
import { Provider as ToastProvider } from './src/context/toast'
import { RouteUpdateArgs } from 'gatsby'

initKea(false)

export const wrapRootElement = ({ element }) => <ToastProvider>{wrapElement({ element })}</ToastProvider>
export const onRouteUpdate = ({ location, prevLocation }: RouteUpdateArgs) => {
    // This is checked and set on initial load in the body script set in gatsby-ssr.js
    // Checking for prevLocation prevents this from happening twice
    if (typeof window !== 'undefined' && prevLocation) {
        var slug = location.pathname.substring(1)
        // var theme =
        //     /^how-we-work|^team|^docs|^blog|^integrations|^tutorials|^questions|^manual|^start-icloudready|^support/.test(
        //         slug
        //     )
        //         ? (window as any).__theme
        //         : 'light'
        var theme = (window as any).__theme || 'light'
        document.body.className = theme
    }

    if (window?.posthog) {
        if (prevLocation) {
            window.posthog.capture('$pageleave', {
                $host: prevLocation.host,
                $pathname: prevLocation.pathname,
                $current_url: prevLocation.href,
            })
        }

        window?.posthog?.capture('$pageview')
    }
    if (window?.fbq) {
        window?.fbq('track', 'PageView')
    }
}
export const wrapPageElement = ({ element, props }) => {
    return props.custom404 || !props.data ? element : element
}

// export const wrapPageElement = ({ element, props }) => {
//     const slug = props.location.pathname.substring(1)
//     return props.custom404 || !props.data ? (
//         element
//     ) : /^how-we-work|^team|^docs\/(?!api)|^manual/.test(slug) &&
//       ![
//           'docs/api/units',
//           'docs/integrations',
//           'docs/property-management',
//           'docs/real-estate-crm',
//           'docs/real-estate-marketing',
//           'docs/listings-hub',
//           'docs/real-estate-mobile-apps',
//           'docs/transaction-management',
//           'docs/websites',
//           'docs/analytics',
//       ].includes(slug) ? (
//         <HowWeWorkLayout {...props} />
//     ) : // ) : /^our-teams\//.test(slug) ? (
//     //     <HowWeWorkLayout {...props} />
//     // /^real-estate-crm|^property-management|^real-estate-marketing|^real-estate-mobile-apps|^transaction-management|^websites|^real-estate-platform/.test(
//     //       slug
//     //   ) ? (
//     //     <Product {...props} />
//     // ) :
//     // ) : /^markets\/commercial|^markets\/residential|^markets\/industrial|^markets\/brokers|^markets\/agents|^markets\/real-estate-developers|^markets\/commercial/.test(
//     //       slug
//     //   ) ? (
//     //     <Market {...props} />
//     /^jobs\//.test(slug) ? (
//         <Job {...props} />
//     ) : (
//         // ) : /^careers\//.test(slug) ? (
//         //     <GoogleDocsJob {...props} />
//         // ) : /^webinars-events\//.test(slug) ? (
//         //     <Event {...props} />
//         // ) : /^academy|^faq|^media|^privacy|^on-permise|^application-received|^startups|^terms|^services/.test(slug) ? (
//         //     <Plain {...props} />
//         element
//     )
// }
