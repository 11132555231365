import { Check2, Close } from 'components/Icons/Icons'
import { IToast, useToast } from '../../hooks/toast'
import React from 'react'
import { createPortal } from 'react-dom'
import { AnimatePresence, motion } from 'framer-motion'

export const Toast = (props: IToast & { index: number }): JSX.Element => {
    const { removeToast, toasts } = useToast()
    const { error, message, createdAt, index } = props
    return (
        <motion.li
            initial={{ translateY: '100%', opacity: 0 }}
            animate={{ translateY: `-${10 * (toasts.length - index)}px`, opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`fixed bottom-4 right-4 mt-2 flex w-full max-w-[350px] items-center space-x-4 rounded-md border border-dashed border-gray-accent-light bg-white py-2 px-4 text-sm font-semibold shadow-lg`}
        >
            <span className="flex-shrink-0">
                {error ? <Close opacity={1} className="h-3 w-3 text-red" /> : <Check2 className="h-4 w-4 text-green" />}
            </span>
            <span className="flex-grow">{message}</span>
            <button className="ml-auto flex-shrink-0" onClick={() => removeToast(createdAt)}>
                <Close className="h-3 w-3" opacity={0.2} />
            </button>
        </motion.li>
    )
}

export default function Toasts(): JSX.Element {
    const { toasts } = useToast()

    return createPortal(
        <ul className="m-0 list-none p-0">
            <AnimatePresence>
                {toasts.map((toast: IToast, index: number) => (
                    <Toast key={toast.createdAt} {...toast} index={index} />
                ))}
            </AnimatePresence>
        </ul>,
        document.body
    )
}
